export const divideIntoColumns = (data, numColumns) => {

  const classNames = ["shortimg", "longimg", "mediumimg"];

    const columns = Array.from({ length: numColumns }, () => []);
    data.forEach((item, index) => {
      const imageClass = classNames[index % classNames.length];
      columns[index % numColumns].push({file :item?.file,caption: item?.shortDescription,className : imageClass});
    });
    return columns;
  };


  export const divideIntoColumns1 = (data, numColumns) => {

    const classNames = ["shortimg", "longimg", "mediumimg"];
  
      const columns = Array.from({ length: numColumns }, () => []);
      data.forEach((item, index) => {
        const imageClass = classNames[index % classNames.length];
        columns[index % numColumns].push({file :item?.file,caption: item?.content,className : imageClass});
      });
      return columns;
    };