import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { useTranslation } from 'react-i18next'
import { GalleryView } from "../../api/apiFun";
import {divideIntoColumns} from "../../Helper/ImageHelper"


const Gallery = () => {
  const { t } = useTranslation()

  const [galleryData, setGalleryData] = useState([{}])
  const [selectedType, setSelectedType] = useState("Image"); 

  const handleTabClick = (type) => {
    setSelectedType(type); 
    GalleryData(type)
  };


  const GalleryData = (type) =>{
    try {
      let obj = {
        type: type
      }
      GalleryView(obj).then((result,err)=>{
        if(result && result.status == true){
          setGalleryData(result?.data)

        }else{
          console.log("err",err);
        }
      })
    } catch (error) {
        console.log("error",error);
    }
  }

  useEffect(() => {
    GalleryData("Image")
    window.scrollTo(0, 0);
  }, []);
  if(selectedType === "Image"){

    var columns = divideIntoColumns(galleryData, 4);
  }else{
    var columns = galleryData
  }

  return (
    <div className="Gallery">
      <section className="inner-banner-Gallery">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-images"></div>

      <div className="container mt-5">
        <div className="row">
          <div className="Translations-image">
            <nav>
              <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  onClick={() => handleTabClick("Image")}
                  className={`nav-link ${selectedType === "Image" ? "active" : ""}`}
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  {t('காட்சி')}
                </button>
                <button
                  onClick={() => handleTabClick("Video")}
                  className={`nav-link ${selectedType === "Video" ? "active" : ""}`}
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {t('காணொளி')}
                </button>
              </div>
            </nav>
          </div>
          <div className="Translations-video">
            <div class="tab-content p-3 " id="nav-tabContent">
              {selectedType === "Image"  ? 
                  <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className="gallery-collapse">
                    {columns?.map((column, colIndex) => (
                      <div key={colIndex} className="gallery__column">
                        {column?.map((image) => (
                          <a
                            key={image?.id}
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="gallery__link"
                          >
                            <figure className="gallery__thumb">
                              <img
                                loading="lazy"
                                src={ image?.file}
                                className={`img-fluid ${image?.className}`}
                                alt="icon"
                              />
                              <figcaption className="gallery__caption">
                                {image?.caption}
                              </figcaption>
                            </figure>
                          </a>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>   : ""}


              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="row mt-5">
                {columns.map((column, colIndex) => (
                  
                  <div key={colIndex} className="col-lg-4" >
                    <div className="campaign-videos">
                      <div className="video-container">
                        <iframe
                          width="1280"
                          height="720"
                          src={column?.link}
                          title={column?.shortDescription}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>

                      </div>
                    </div>{" "}
                  </div>
                ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Gallery;
